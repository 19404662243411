import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/layout/Layout';
import Home from './components/main/Home/Home';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SignUp from './components/main/SignUp/SignUp';
import Agreements from './components/main/SignUp/Agreements/Agreement';
import Compensate from './components/main/SignUp/Compensate/Compensate';

function App() {
  return (
    <div className="App-wraper">
      <Router>
        <Routes>
          {/* Guest routes here */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/sign-up" element={<SignUp />} />
            {/* <Route path="*" element={<NoPageFound />} /> */}
            {/* Add your public routes here eg. <Route path="example" element={<Component />} /> */}
          </Route>
          {/* <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} /> */}
          {/* <Route path="/agreement" element={<Agreements />} />
          <Route path="/compensate" element={<Compensate />} /> */}
        </Routes>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
