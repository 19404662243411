import React from "react";
import "./OurServices.css";
import connectivity from "../../../../assets/images/connectivity.png";
import partner from "../../../../assets/images/partner-benefits.png";
import { Link } from "react-router-dom";

function OurServices() {
  return (
    <>
      <div className="our-services">
        <div className="custom-container">
          <div className="services-wraper">
            <div className="services-benfits">
              <img
                src={partner}
                alt="partner-benefits-img"
                className="img-fluid"
              />
            </div>
            <div className="Partner-Benefits">
              <h5>Unlock Affiliation Access:</h5>
              <h4>Partner Benefits</h4>
              <p>
                By becoming our partner, your business gains exclusive access to
                our network of major internet and TV providers. This affiliation
                access opens up a world of opportunities for collaboration and
                growth, allowing you to tap into the resources and reach of
                these industry giants. Partner with us today to leverage these
                valuable affiliations and take your business to the next level.
              </p>
              <div class="default-btn">
                <Link to="sign-up"><button class="btn theme-btn">Become A Partner</button></Link>
              </div>
            </div>
          </div>
          <div className="services-wraper mt-102">
            <div className="Partner-Benefits ml-0 affordable_connectivity">
              <h5>What Is The:</h5>
              <h4>Affordable Connectivity Program?</h4>
              <p>
                CP is a longer-term replacement for the Emergency Broadband
                Benefit, designed to help keep families connected to the
                Internet. Qualified households can receive a monthly credit of
                up to $30/month (up to $75/month for customers in Tribal lands)
                towards their Internet and mobile services.
              </p>
              <div class="default-btn">
                <button class="btn theme-btn">Learn More</button>
              </div>
            </div>
            <div className="services-benfits ">
              <img
                src={connectivity}
                alt="partner-benefits-img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurServices;
