import React, { useEffect, useState } from "react";
// __ __ Packages/Style/Media __ __ //
import StepWizard from "react-step-wizard";
import "./SignUp.css";
// __ __ Components/Helpers __ __ //
import FirstForm from "./Forms/FirstForm";
import SecondForm from "./Forms/SecondForm";
import ThirdForm from "./Forms/ThirdForm";
import FourthForm from "./Forms/FourthForm";
import FifthForm from "./Forms/FifthForm";
import ProgressBar from "../Common/ProgressBar/ProgressBar";
import ThanksPage from "./Forms/ThanksPage";

import { toast } from "react-toastify";
import SignupBanner from "./SignupBanner/SignupBanner";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const ORIGIN = process.env.REACT_APP_ORIGIN;

const headers = {
  "Content-Type": "application/json",
  origin: ORIGIN, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

// const apiUrl = `${API_URL}/partner/create`;
const apiUrl = `${API_URL}/leads/create`;
const SignUp = () => {
  const [step, setStep] = useState(1);
  const [flag, setFlag] = useState(true);
  const [data, setData] = useState({
    fullname: "",
    business: "",
    phone: "",
    email: "",
    // agreement: false,
    signersName: "",
    signersTitle: "",
    sign: "",
    compensate: false,
    accountName: "",
    routingNumber: "",
    accountNumber: "",
  });

  const createPartner = () => {
    const formData = new FormData(); // Create a new FormData object

    for (const key of Object.keys(data)) {
      if (key !== "sign") {
        const value = data[key];
        formData.append(key, value);
      } else {
        formData.append("sign", data.sign);
      }
    }
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({ ...data, source: "Sign Up" }),
    };
    toast.dismiss();
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            response.status == 429
              ? "Too many requests from this IP, please try again after a day"
              : "Something went wrong"
          );
        }
        return response.json(); // Parse the response body as JSON
      })
      .then((data) => {
        // Handle the successful response here
        // toast.success("Thanks for contacting us.");
        console.log("Response data:", data);
        setData({});
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        toast.error("" + error);
        console.log("Error:", error);
      });
  };
  return (
    <React.Fragment>
      <SignupBanner />
      <div className="margin-progress">
        <ProgressBar step={step} />
      </div>
      <StepWizard>
        <FirstForm setStep={setStep} setData={setData} data={data} />
        <SecondForm setStep={setStep} setData={setData} data={data} />
        <ThirdForm setStep={setStep} setData={setData} data={data} />
        <FourthForm setStep={setStep} setData={setData} data={data} />
        <FifthForm
          setStep={setStep}
          setData={setData}
          data={data}
          createPartner={createPartner}
          setFlag={setFlag}
          flag={flag}
        />
        <ThanksPage flag={flag} />
      </StepWizard>
    </React.Fragment>
  );
};

export default SignUp;
