import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import "./SignupBanner.css";
import "../../Home/Banner/Banner.css";

function SignupBanner() {
    return (
        <div>
            <div className='signup-baner'>
                <Container fluid className="custom-container">
                    <Row className='content--row'>
                        <Col lg={7}>
                            <div className='baner-content'>
                                <h5 className='theme-clr'>THE WORLDS ONLY</h5>
                                <h3 className='green-clr'>RESIDENTIAL AND COMMERCIAL</h3>
                                <h1 className='theme-clr'>MASTER AGENT</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SignupBanner
