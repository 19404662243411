import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  fullname: "",
  businessName: "",
  phone: "",
  email: "",
  typeOfCompany: "",
  hearAboutUs: "",
  companyWebsite: "",
  optIn: false,
};

const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Fullname  must contain only letters and spaces"),
  businessName: Yup.string().required("Business name is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be exactly 10 digits")
    .length(14, "Phone number must be exactly 10 digits"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  typeOfCompany: Yup.string()
    .required("Please select a type of company")
    .matches(/^[A-Za-z\s]+$/, "State must contain only letters and spaces")
    .nullable(),
  hearAboutUs: Yup.string().nullable(),
  companyWebsite: Yup.string()
    .nullable()
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-]+)*\/?$/,
      "Invalid website format"
    ),
  optIn: Yup.boolean()
    .oneOf([true], "You must agree to the terms")
    .required("Agreement is required"),
});

const typeOfCompany = [
  "Moving Company",
  "Real Estate Agency",
  "Real Estate Agent",
  "Property Manager",
  "Affiliate Marketer",
  "Call Center",
  "Outside Sales Company",
  "Builder",
  "Telecom Provider/Reseller",
  "Other",
];

const hearAboutUs = [
  "Facebook",
  "LinkedIn",
  "Google",
  "Comlink Employee",
  "Referral",
  "Other",
];

const FirstForm = (props) => {
  // const [errors, setErrors] = useState({});

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   props?.setData({
  //     ...props?.data,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const newErrors = {};
  //   // Validate name
  //   if (!props?.data.fullname.trim()) {
  //     newErrors.fullname = "Name is required";
  //   }
  //   // Validate business
  //   if (!props?.data.business.trim()) {
  //     newErrors.business = "Business name is required";
  //   }

  //   // Validate phone
  //   if (!props?.data.phone.trim()) {
  //     newErrors.phone = "Phone is required";
  //   } else if (!/^\d{10}$/.test(props?.data.phone)) {
  //     newErrors.phone = "Phone number should be 10 digits";
  //   }

  //   // Validate email
  //   if (!props?.data.email.trim()) {
  //     newErrors.email = "Email is required";
  //   } else if (
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(props?.data.email)
  //   ) {
  //     newErrors.email = "Invalid email address";
  //   }

  //   // If there are errors, set them in state
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //   } else {
  //     // Form is valid, you can submit the data or perform other actions here
  //     setErrors({});
  //     props.setStep(2);
  //     props.nextStep();
  //     console.log("Form data:", props?.data);
  //   }
  // };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      props?.setData({
        ...props?.data,
        ...values,
      });
      props.setStep(2);
      props.nextStep();
    },
  });

  return (
    <div className="Qoute__content">
      <div className="custom-container">
        <div className="contact-headings text-center">
          <h4 className="theme-clr mb-lg-3 mb-2">Basic Information</h4>
          <p>
            Leave your information below, one of our representatives will get in
            touch with you.
          </p>
        </div>
        <div className="qoute__form">
          <div className="row">
            <div className="col-xl-3">
              <div className="form_image">
                {/* <img src={QouteForm} alt="QouteForm" /> */}
              </div>
            </div>
            <div className="col-xl-9">
              <div className="form_content">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="fullname">
                          Your Name<span className="red_staric">*</span>
                        </label>
                        <input
                          id="fullname"
                          name="fullname"
                          type="text"
                          placeholder="Enter your name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fullname}
                          className={`form-control ${
                            formik.errors.fullname && formik.touched.fullname
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.fullname && formik.touched.fullname && (
                          <div className="red_staric">
                            {formik.errors.fullname}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="businessName">
                          Business Name<span className="red_staric">*</span>
                        </label>
                        <input
                          id="businessName"
                          name="businessName"
                          type="text"
                          placeholder="Enter your business name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.businessName}
                          className={`form-control ${
                            formik.errors.businessName &&
                            formik.touched.businessName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.businessName &&
                          formik.touched.businessName && (
                            <div className="red_staric">
                              {formik.errors.businessName}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="phone">
                          Phone<span className="red_staric">*</span>
                        </label>
                        <ReactInputMask
                          id="phone"
                          name="phone"
                          mask="(999) 999-9999"
                          type="text"
                          placeholder="Enter your phone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          className={`form-control ${
                            formik.errors.phone && formik.touched.phone
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.phone && formik.touched.phone && (
                          <div className="red_staric">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="email">
                          Email<span className="red_staric">*</span>
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter your email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className={`form-control ${
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="red_staric">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="typeOfCompany">
                          Type of company<span className="red_staric">*</span>
                        </label>
                        <select
                          id="typeOfCompany"
                          name="typeOfCompany"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.typeOfCompany}
                          className={`form-control ${
                            formik.errors.typeOfCompany &&
                            formik.touched.typeOfCompany
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option
                            value=""
                            selected
                            hidden
                            label="Select a type of company"
                          />
                          {typeOfCompany.map((type, index) => (
                            <option key={index} value={type} label={type} />
                          ))}
                        </select>
                        {formik.errors.typeOfCompany &&
                          formik.touched.typeOfCompany && (
                            <div className="red_staric">
                              {formik.errors.typeOfCompany}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="companyWebsite">
                          Website of your company
                        </label>
                        <input
                          id="companyWebsite"
                          name="companyWebsite"
                          type="text"
                          placeholder="Enter your company site"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.companyWebsite}
                          className={`form-control ${
                            formik.errors.companyWebsite &&
                            formik.touched.companyWebsite
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors.companyWebsite &&
                          formik.touched.companyWebsite && (
                            <div className="red_staric">
                              {formik.errors.companyWebsite}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-xl-4 mb-lg-3 mb-2">
                        <label htmlFor="hearAboutUs">
                          How did you hear about us:
                        </label>
                        <select
                          id="hearAboutUs"
                          name="hearAboutUs"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.hearAboutUs}
                          className={`form-control ${
                            formik.errors.hearAboutUs &&
                            formik.touched.hearAboutUs
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option
                            value=""
                            selected
                            hidden
                            label="Select a reference"
                          />
                          {hearAboutUs.map((hearUs, index) => (
                            <option key={index} value={hearUs} label={hearUs} />
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <label className="custom-control overflow-checkbox relative">
                        <input
                          name="optIn"
                          className="overflow-control-input"
                          type="checkbox"
                          onChange={formik.handleChange}
                          checked={formik.values.optIn}
                        />
                        <span className="overflow-control-indicator "></span>
                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">
                          <div className="Submitting_form box main-wrapper-tooltip">
                            <label className="pl-25">
                              <p>
                                I agree to the
                                <span className="tooltip-wrapper">
                                  <span className="tooltips">
                                    Terms & Conditions
                                  </span>
                                  <span className="tooltiptext">
                                    By submitting this form, you acknowledge
                                    that you permit Comlink Total Solutions
                                    express consent to contact you at the number
                                    and/or email address you have provided above
                                    with automated technology in relation to
                                    this inquiry via phone, e-mail, or text
                                    message. You understand that making a
                                    purchase is not required to consent to
                                    receive communications from Comlink Total
                                    Solutions.
                                  </span>
                                </span>
                              </p>
                            </label>
                          </div>
                        </span>
                      </label>
                      {formik.errors.optIn && formik.touched.optIn && (
                        <div className="red_staric">{formik.errors.optIn}</div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
                    <button type="submit" className="btn theme-btn">
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstForm;
