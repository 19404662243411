import React from "react";

const Tick = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.404"
      height="23.522"
      viewBox="0 0 25.404 23.522"
    >
      <g
        id="Group_3651"
        data-name="Group 3651"
        transform="translate(-193.772 -236.76)"
      >
        <line
          id="Line_716"
          data-name="Line 716"
          x2="5.996"
          y2="5.997"
          transform="translate(198.015 250.043)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          strokeWidth="6"
        ></line>
        <line
          id="Line_717"
          data-name="Line 717"
          x1="10.975"
          y2="15.087"
          transform="translate(204.01 240.951)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          strokeWidth="6"
        ></line>
      </g>
    </svg>
  );
};

export default Tick;
