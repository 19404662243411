import siteLogo from "../assets/images/comlink-partner.svg";
import bannerimg1 from "../assets/images/artboard-new.png";
import footerLogo from "../assets/images/comlink-partner-program-white.png";
import crm1 from "../assets/images/crm-1.svg";
import crm2 from "../assets/images/crm-2.svg";
import crm3 from "../assets/images/crm-3.svg";
import crm4 from "../assets/images/crm-4.svg";
import comlinktv from "../assets/images/comlinktv-logo.svg";
import comlinkinternet from "../assets/images/comlink-internet.svg";
import comlinkmobile from "../assets/images/comlink-mobile.svg";
import comlinkAtd from "../assets/images/aut-logo.png";
import comlinkatat from "../assets/images/atat-logo.png";
import comlinkfiber from "../assets/images/altafiber-logo.png";
import alderlogo from "../assets/images/alder-logo.png";
import breezeline from "../assets/images/breezeline-logo.png";
import brightSpeed from "../assets/images/SVG Logo/Bright Speed.svg";
import cox from "../assets/images/SVG Logo/COX.svg";
import consolidatedCommunications from "../assets/images/SVG Logo/Consolidated Communications.svg";
import directTv from "../assets/images/SVG Logo/Direct TV.svg";
import dish from "../assets/images/SVG Logo/Dish.svg";
import earthLink from "../assets/images/SVG Logo/Earth Link.svg";
import frontier from "../assets/images/SVG Logo/Frontier.svg";
import frontpoint from "../assets/images/SVG Logo/Frontpoint.svg";
import googleFiber from "../assets/images/SVG Logo/Google Fiber.svg";
import homeInternet from "../assets/images/SVG Logo/Home Internet.svg";
import hughesNet from "../assets/images/SVG Logo/Hughes Net.svg";
import metroNet from "../assets/images/SVG Logo//Metro Net.svg";
import optimum from "../assets/images/SVG Logo/Optimum.svg";
import reliant from "../assets/images/SVG Logo/Reliant.svg";
import spectrum from "../assets/images/SVG Logo/Spectrum.svg";
import verizon from "../assets/images/SVG Logo/Verizon.svg";
import viasat from "../assets/images/SVG Logo/Viasat.svg";
import vivint from "../assets/images/SVG Logo/Vivint.svg";
import wow from "../assets/images/SVG Logo/WOW.svg";
import windStream from "../assets/images/SVG Logo/Wind Stream.svg";
import xfinity from "../assets/images/SVG Logo/Xfinity.svg";
import youtubeTv from "../assets/images/SVG Logo/Youtube Tv.svg";
import ziplyFiber from "../assets/images/SVG Logo/Ziply Fiber.svg";
import quoteimg from "../assets/images/contact-img.png";






export const Images = {
    siteLogo,
    footerLogo,
    bannerimg1,
    crm1,
    crm2,
    crm3,
    crm4,
    comlinktv,
    comlinkinternet,
    comlinkmobile,
    comlinkAtd,
    comlinkatat,
    comlinkfiber,
    alderlogo,
    breezeline,
    brightSpeed,
    cox,
    consolidatedCommunications,
    directTv,
    dish,
    earthLink,
    frontier,
    frontpoint,
    googleFiber,
    homeInternet,
    homeInternet,
    metroNet,
    optimum,
    reliant,
    spectrum,
    verizon,
    viasat,
    vivint,
    wow,
    windStream,
    xfinity,
    youtubeTv,
    ziplyFiber,
    quoteimg,
    hughesNet,
};
