import React from "react";
import {Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { Images } from "../../../../assets/assets";
import "./Compensate.css";

function Compensate() {
  return (
    <>
      <div className="compensate-pg w-100">
        <Row>
          <Col lg={12}>
            <div className="compensate-content d-flex flex-column justify-content-center align-items-center">
              <h5 className="green-clr text-center">Compensation</h5>
              <h4 className="theme-clr text-center">Comprehensive Insights</h4>
            </div>
          </Col>
        </Row>
        <div className="compensate-pdf-text">
          <Row>
            <Col lg={12}>
              <h2 className="theme-clr text-center">Compensation Plan!</h2>
            </Col>
            <Col lg={12}>
              <div className="compensate-accordian">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkinternet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>COMLINK TV PACKAGES</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials starter 25GB</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Elite starter 50GB</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Essentials 300GB</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Elite 500GB</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>5G Wideband Unlimited</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge Backs</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinktv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Comlink Wifi</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials TV</th>
                            <td>$5</td>
                          </tr>
                          <tr>
                            <th>Elite TV</th>
                            <td>$7</td>
                          </tr>
                          <tr>
                            <th>Ultimate TV</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge Backs</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkmobile}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Comlink Mobility</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Starter Plan per line</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>Essentials Plan per line</th>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>Premier Plan per line</th>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>Elite Plan per line</th>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>Ultra Plan per line</th>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>ACP TABLET PLAN</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>ACP SIM OR PHONE PLAN</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge Backs</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p>Leads</p>
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Leads</th>
                            <th>Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Residential Veri ed Lead</th>
                            <td>0.50 cents per lead</td>
                          </tr>
                          <tr>
                            <th>Business Lead Veri ed Lead</th>
                            <td>0.75 cents per lead</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>No Charge backs</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p>Residential Carriers</p>
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>DSL Internet</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>Cable Internet</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Fiber Internet</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Satellite Internet</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Home Phone</th>
                            <td>$5</td>
                          </tr>
                          <tr>
                            <th>Wireless Phone Service</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Cable TV</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Satellite TV</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Home Security</th>
                            <td>$150</td>
                          </tr>
                          <tr>
                            <th>Energy</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Solar</th>
                            <td>$200</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="row logo-wraper-row">

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkatat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkAtd}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.alderlogo}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkfiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.breezeline}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.brightSpeed}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.cox}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.consolidatedCommunications}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.dish}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.directTv}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.earthLink}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontier}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontpoint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.googleFiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>

                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.hughesNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.metroNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.optimum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.reliant}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.spectrum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.homeInternet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.verizon}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.viasat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.vivint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.windStream}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.wow}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.xfinity}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.youtubeTv}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.ziplyFiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <p>Business Carriers</p>
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>DSL Internet</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Cable Internet</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Fiber Internet</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>Satellite Internet</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Business Phone</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Wireless Phone Service</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Cable TV</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Satellite TV</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Business Security</th>
                            <td>$150</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="row logo-wraper-row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkatat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkAtd}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.alderlogo}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.comlinkfiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.breezeline}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.brightSpeed}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.cox}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.dish}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.directTv}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.earthLink}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontier}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.frontpoint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.googleFiber}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>

                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.hughesNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.metroNet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.optimum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.spectrum}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.homeInternet}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.verizon}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.viasat}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.vivint}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.windStream}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.wow}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                          <div className="logo-img-wraper">
                            <img
                              src={Images.xfinity}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinktv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>COMLINK TV PACKAGES</th>
                            <th>Option 1 Residual (life of the customer)</th>
                            <th>Option 2 One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials TV</th>
                            <td>$10</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Elite TV</th>
                            <td>$12</td>
                            <td>$70</td>
                          </tr>
                          <tr>
                            <th>Ultimate TV</th>
                            <td>$12</td>
                            <td>$70</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>30 Days</td>
                            <td>180 Days</td>
                          </tr>
                          <tr>
                            <th>Equipment</th>
                            <td>One-Time Payment</td>
                            <td>One-Time Payment</td>
                          </tr>
                          <tr>
                            <th>Android Stick</th>
                            <td>$20</td>
                            <td>30 Days</td>
                          </tr>
                          <tr>
                            <th>Set Top Box</th>
                            <td>$20</td>
                            <td>30 Days</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkinternet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Comlink Wifi</th>
                            <th>Option 1 Residual (life of the customer)</th>
                            <th>Option 2 One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials starter 25GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Elite starter 50GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Essentials 300GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Essentials 300GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Elite 500GB</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>5G Wideband Unlimited</th>
                            <td>$15</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>Equipment</th>
                            <td>One-Time Payment</td>
                            <td>Charge back Window</td>
                          </tr>
                          <tr>
                            <th>LTE Router</th>
                            <td>100</td>
                            <td>30 days</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkmobile}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Comlink Mobility</th>
                            <th>Option 1 Residual (life of the customer)</th>
                            <th>Option 2 One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Essentials Plan per line</th>
                            <td>$5</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Elite Plan per line</th>
                            <td>$5</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>ACP TABLET PLAN</th>
                            <td>$8</td>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>ACP SIM OR PHONE PLAN</th>
                            <td>$7</td>
                            <td>$15</td>
                          </tr>
                          <tr>
                            <th>Charge Back Window</th>
                            <td>30 days</td>
                            <td>180 days</td>
                          </tr>
                          <tr>
                            <th>Equipment</th>
                            <td>One-Time Payment</td>
                            <td>Charge back Window</td>
                          </tr>
                          <tr>
                            <th>IPhone Sale</th>
                            <td>$100</td>
                            <td>30 days</td>
                          </tr>
                          <tr>
                            <th>Andriod Sale</th>
                            <td>$100</td>
                            <td>30 days</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  {/* <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkAtd}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Home Security (Owners)</th>
                            <td>$240.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.alderlogo}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Home Security (Renters)</th>
                            <td>$150.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkatat}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>ATT Internet Service</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>AT&T Internet 1 Gig+</th>
                            <td>$250.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 500Mbps+</th>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 300Mbps+</th>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 100Mbps+ </th>
                            <td>$75.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 50-75Mbps</th>
                            <td> $75.00 </td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 50Mbps</th>
                            <td> $60.00</td>
                          </tr>
                          <tr>
                            <th>ATT Fiber Upgrade Internet</th>
                            <td>$75.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 1GIG +</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 500 mbps</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>AT&T Internet 30</th>
                            <td>$35.00 </td>
                          </tr>
                          <tr>
                            <th>ATT Wireless</th>
                            <td> </td>
                          </tr>
                          <tr>
                            <th>Installment Plan Gross Add</th>
                            <td>$100.00 per line </td>
                          </tr>
                          <tr>
                            <th>Installment Plan Next Up</th>
                            <td>$10.00 per line </td>
                          </tr>
                          <tr>
                            <th>Installment Plan Unlimited Extra</th>
                            <td>$10.00 per line</td>
                          </tr>
                          <tr>
                            <th>Installment Plan Unlimited Elite </th>
                            <td>$10.00 per line</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.comlinkfiber}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>750MB - 1GB</th>
                            <td>$68</td>
                          </tr>
                          <tr>
                            <th>100MB - 500MB</th>
                            <td>$58</td>
                          </tr>
                          <tr>
                            <th>10MB - 50MB</th>
                            <td>$42</td>
                          </tr>
                          <tr>
                            <th>DSL 10MB</th>
                            <td>$30</td>
                          </tr>
                          <tr>
                            <th>Phone </th>
                            <td>$$12</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.breezeline}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>TV</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>$20</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.brightSpeed}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Brightspeed Residential</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>High Speed Internet</th>
                            <td>20 Mbps or Higher</td>
                            <td>$80</td>
                          </tr>
                          <tr>
                            <th>High Speed Internet</th>
                            <td>19 Mbps or lower</td>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Voice</th>
                            <td>Home Phone</td>
                            <td>$20</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.consolidatedCommunications}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>2GIG</th>
                            <td>$225.00</td>
                          </tr>
                          <tr>
                            <th>1GIG</th>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <th>250MBPS</th>
                            <td>$88.00</td>
                          </tr>
                          <tr>
                            <th>50MBPS</th>
                            <td>$63.00</td>
                          </tr>
                          <tr>
                            <th>Voice</th>
                            <td>$12.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img src={Images.cox} className="img-fluid" alt="img" />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>COX RESIDENTIAL</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Voice</th>
                            <th>Voip Line</th>
                            <td>$20.00</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <th>Cable</th>
                            <td>$40.00</td>
                          </tr>
                          <tr>
                            <th>High Speed Internet</th>
                            <th>26 Mbps or Higher</th>
                            <td>$80.00</td>
                          </tr>
                          <tr>
                            <th>High Speed Internet</th>
                            <th>25 Mbps or Lower</th>
                            <td>$20.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.directTv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>DIRECTV STREAM</th>
                            <td>$150</td>
                          </tr>
                          <tr>
                            <th>DIRECTV SATELLITE (MEDIUM CREDIT CLASS)</th>
                            <td>$85</td>
                          </tr>
                          <tr>
                            <th>DIRECTV SATELLITE (LOW CREDIT CLASS)</th>
                            <td>$200</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.dish}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>TV</th>
                            <td>$140</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.earthLink}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Earthlink {"<"} 100MBPS</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Speeds up to 99mbps</th>
                            <td>$90</td>
                          </tr>
                          <tr>
                            <th>Speeds 100+mbps</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>12 AT&T Markets - 100mbps</th>
                            <td>$125</td>
                          </tr>
                          <tr>
                            <th>12 AT&T Markets - 300mbps</th>
                            <td>$175</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Earthlink Wireless Internet</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>150 Gigabyte Plan</th>
                            <td>$80</td>
                          </tr>
                          <tr>
                            <th>100 Gigabyte Plan</th>
                            <td>$60</td>
                          </tr>
                          <tr>
                            <th>75 Gigabyte Plan</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>50 Gigabyte Plan</th>
                            <td>$25</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.frontpoint}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Plans</th>
                            <th>Dealer One-time Compensation (NEW SERVICE)</th>
                            <th>
                              Dealer One-time Compensation (Transfer of service)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Home Security</th>
                            <td>$300</td>
                            <td>$200</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.frontier}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>ALL FIBER SALES</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>ALL DSL SALES</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>COPPER TO FIBER UPGRADES</th>
                            <td>$75</td>
                          </tr>
                          <tr>
                            <th>VOICE</th>
                            <td>$20</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="16">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.googleFiber}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>ALL FIBER SALES</th>
                            <td>$72</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="17">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.hughesNet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <td>$90</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="18">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.metroNet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>MetroNet Residential</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>1GB</th>
                            <td>$133</td>
                          </tr>
                          <tr>
                            <th>500 MB</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>200 MB</th>
                            <td>$80</td>
                          </tr>
                          <tr>
                            <th>100 MB</th>
                            <td>$60</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>$20</td>
                          </tr>
                          <tr>
                            <th>Whole Home Wi-Fi</th>
                            <td>$10</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>MetroNet Student</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>100 MB</th>
                            <td>$40</td>
                          </tr>
                          <tr>
                            <th>500 MB</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>1GB</th>
                            <td>$63</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>Whole Home Wi-Fi</th>
                            <td>$10</td>
                          </tr>
                          <tr>
                            <th>MetroNet Residential</th>
                            <td>Dealer Comp</td>
                          </tr>
                          <tr>
                            <th>1GB</th>
                            <td>$265</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="19">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.optimum}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>
                              Compensation to Distributor (One-time payment)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet Tier 1 or 2</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>Internet Tier 3 or 4</th>
                            <td>$70.00</td>
                          </tr>
                          <tr>
                            <th>Voice</th>
                            <td>$20.00</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <td>$20.00</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="20">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.reliant}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>NRG/Reliant</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Tier 1</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>Tier 2</th>
                            <td>$88</td>
                          </tr>
                          <tr>
                            <th>Tier 3</th>
                            <td>$113</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="21">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.spectrum}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Spectrum Service</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Spectrum Internet 1GIG</th>
                            <td>$70.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum Internet Ultra</th>
                            <td>$60.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum Internet</th>
                            <td>$50.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum TV - Select / Latino</th>
                            <td>$25.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum TV - Choice</th>
                            <td>$12.50</td>
                          </tr>
                          <tr>
                            <th>Spectrum Phone</th>
                            <td>$20.00</td>
                          </tr>
                          <tr>
                            <th>Spectrum Mobility</th>
                            <td>$75.00 per line</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="22">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.homeInternet}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Product TYPE</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <th>5G/4G</th>
                            <td>$40</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="23">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.verizon}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Product TYPE</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <th>FIOS</th>
                            <td>$95</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>5G</th>
                            <td>$84</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>DSL</th>
                            <td>$32</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="24">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.viasat}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Product TYPE</th>
                            <th>One-Time Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>High Speed Internet</th>
                            <th>Full Beam</th>
                            <td>$125</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>Other </th>
                            <td>$95</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="25">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.vivint}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Vivint SmartHome</th>
                            <th>Dealer Compensation</th>
                            <th>
                              Volume Tiers Retro to 1st Activation Monthly
                            </th>
                            <th>Bonus Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>$599 Starter Kit @ $29.99/mo</th>
                            <th>$75</th>
                            <th>10 installs/mo</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $39.99/mo</th>
                            <th>$212</th>
                            <th>75 Installs/mo</th>
                            <td>$25</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $44.99/mo</th>
                            <th>$238</th>
                            <th>100 Installs/mo</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $49.99/mo</th>
                            <th>$263</th>
                            <th>125 Installs/mo</th>
                            <td>$75</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $54.99/mo</th>
                            <th>$275</th>
                            <th>150 Installs/mo</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $59.99/mo</th>
                            <th>$288</th>
                            <th>175 Installs/mo</th>
                            <td>$125</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $64.99/mo</th>
                            <th>$313</th>
                            <th>200 Installs/mo</th>
                            <td>$150</td>
                          </tr>
                          <tr>
                            <th>$599 Starter Kit @ $69.99/mo</th>
                            <th>$338</th>
                            <th>250 Installs/mo</th>
                            <td>$175</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $19.99/mo</th>
                            <th>$240</th>
                            <th>300 Installs/mo</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $24.99/mo</th>
                            <th>$265</th>
                            <th>350 Installs/mo</th>
                            <td>$225</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $29.99/mo</th>
                            <th>$290</th>
                            <th>400 Installs/mo</th>
                            <td>$250</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $34.99/mo</th>
                            <th>$302</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $39.99/mo</th>
                            <th>$315</th>
                            <th>-</th>
                            <td>- </td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $44.99/mo</th>
                            <th>$340</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>$1799 Starter Kit @ $49.99/mo</th>
                            <th>$365</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="26">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.windStream}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Windstream/Kinetic (Saraplus OES)</th>
                            <th>Dealer Compensation</th>
                            <th>Windstream/Kinetic (Concert OES)</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>{">"}100MPBS</th>
                            <th>$63</th>
                            <th>Internet</th>
                            <td>$30</td>
                          </tr>
                          <tr>
                            <th>100-499MBPS</th>
                            <th>$80</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>500-999MBPS</th>
                            <th>$90</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>1GIG+</th>
                            <th>$103</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle Packages</th>
                            <th>Dealer Comp</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle Voice with HIS</th>
                            <th>$90</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle 100-499MBPS</th>
                            <th>$107</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle 500-999MBPS</th>
                            <th>$118</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Bundle 1GIG+</th>
                            <th>$128</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Secure</th>
                            <th>Dealer Comp</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Secure</th>
                            <th>$3</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Complete</th>
                            <th>$4</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Kinetic Total</th>
                            <th>$5</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="27">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img src={Images.wow} className="img-fluid" alt="img" />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>WOW! (Saraplus OES)</th>
                            <th>Dealer Compensation</th>
                            <th>WOW! (Concert OES)</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>1200MBPS</th>
                            <th>$75</th>
                            <th>INTERNET</th>
                            <td>$30</td>
                          </tr>
                          <tr>
                            <th>1000MBPS</th>
                            <th>$75</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>500MBPS</th>
                            <th>$53</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>200MBPS</th>
                            <th>$50</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>100MBPS</th>
                            <th>$30</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <th>$16</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Whole Home Wifi</th>
                            <th>$20</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th>Unlimited Data</th>
                            <th>$10</th>
                            <th>-</th>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="28">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.xfinity}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Speed</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Internet</th>
                            <th>300 Mbps or Higher</th>
                            <td>$48</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>100 Mbps or Higher</th>
                            <td>$24</td>
                          </tr>
                          <tr>
                            <th>Internet</th>
                            <th>25 Mbps or Higher</th>
                            <td>$16</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <th>Ultimate</th>
                            <td>$72</td>
                          </tr>
                          <tr>
                            <th>Video</th>
                            <th>Other</th>
                            <td>$40</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="29">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.youtubeTv}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Speed</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>TV</th>
                            <th>Stream</th>
                            <td>$40</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="30">
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <img
                          src={Images.ziplyFiber}
                          className="img-fluid"
                          alt="img"
                        />
                        <p>Check Dealer Compensation</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table responsive bordered striped>
                        <thead>
                          <tr>
                            <th>Ziply Fiber</th>
                            <th>Dealer Compensation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>5GIG</th>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <th>2GIG</th>
                            <td>$163</td>
                          </tr>
                          <tr>
                            <th>1GIG</th>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <th>200/200</th>
                            <td>$70</td>
                          </tr>
                          <tr>
                            <th>50/50</th>
                            <td>$50</td>
                          </tr>
                          <tr>
                            <th>DSL</th>
                            <td>$50</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Compensate;
