import React, { useEffect, useRef, useState } from "react";
// __ __ Packages/Style/Media __ __ //
import SignatureCanvas from "react-signature-canvas";
import pdfFile from "../../../../assets/comlink.pdf";
import Agreements from "../Agreements/Agreement";

const SecondForm = (props) => {
  const validationRef = useRef(null);
  const startRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [sign, setSignatureData] = useState(null);
  const signatureRef = React.useRef();

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    props?.setData({
      ...props?.data,
      [name]: checked,
    });
  };
  useEffect(() => {
    const newErrors = {};
    // if (props?.data.agreement == false) {
    //   newErrors.agreement = "Please accept the agreement";
    // }
    if (sign == null) {
      newErrors.sign = "We need your sign for agreement";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [sign]);

  const handleNext = (e) => {
    // e.preventDefault();
    const newErrors = {};
    // Validate agreement
    // if (props?.data.agreement == false) {
    //   newErrors.agreement = "Please accept the agreement";
    // }
    // Validate signersName
    if (!props?.data?.signersName.trim()) {
      newErrors.signersName = "Signers name is required";
    }
    // Validate signersTitle
    if (!props?.data?.signersTitle.trim()) {
      newErrors.signersTitle = "Signers title is required";
    }

    if (sign == null) {
      newErrors.sign = "We need your sign for agreement";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      scrollToSection(validationRef);
    } else {
      setErrors({});
      // setDisable(false);
      props.setData({ ...props.data, sign: sign });
      props.setStep(3);
      props.nextStep();
    }
  };

  
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className="Qoute__content">
      <div className="custom-container">
        <div className="contact-headings text-center">
          <h4 className="theme-clr mb-lg-3 mb-2">Agreement</h4>
          <p>
            Leave your information below, one of our representatives will get in
            touch with you.
          </p>
        </div>
        <div className="qoute__form" ref={startRef}>
          <div className="row">
            <div className="col-xl-3">
              <div className="form_image">
                {/* <img src={QouteForm} alt="QouteForm" /> */}
              </div>
            </div>
            <div className="col-xl-9">
              <div className="form_content p-2">
                <Agreements
                  setSignatureData={setSignatureData}
                  sign={sign}
                  signatureRef={signatureRef}
                  setErrors={setErrors}
                  errors={errors}
                  setData={props?.setData}
                  data={props?.data}
                  // agreement={props?.data?.agreement}
                  handleCheckboxChange={handleCheckboxChange}
                  validationRef={validationRef}
                  scrollToSection={scrollToSection}
                />
                <div className="d-flex  justify-content-between mt-xl-4 mt-3 mb-xl-4 mb-3">
                  <div
                    onClick={() => {
                      props.setStep(1);
                      props.previousStep();
                    }}
                    className="btn theme-btn flex-start"
                  >
                    Previous
                  </div>
                  <button
                    onClick={() => handleNext()}
                    className="btn theme-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondForm;
