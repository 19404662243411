import React, { useRef } from 'react'
import Banner from './Banner/Banner'
import OurBusiness from './OurBusiness/OurBusiness';
import OurServices from './OurServices/OurServices';
import OurTeam from './OurTeam/OurTeam';
import ImprovedCrm from './ImprovedCrm/ImprovedCrm';
import Faqs from '../Common/Faqs/Faqs';
import ContactUs from '../Common/ContactUs/ContactUs';
import CeoMessage from '../Common/CeoMessage/CeoMessage';


function Home() {
	return (
		<div className='homepg-wraper'>
			<Banner />
			<ImprovedCrm/>
			<OurBusiness />
			<OurServices />
			<OurTeam/>
			<CeoMessage/>
			<ContactUs />
			<Faqs/>
		</div>
	)
}

export default Home
