import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Lottie from 'react-lottie-player'
import faq from "../../../../assets/json-files/faq.json";
import "./Faqs.css";

function Faqs() {
    return (
        <div>
            <section className='faqs-section'>
                <Container fluid className="custom-container">
                    <div className='d-flex flex-column faqs-heading'>
                        <h5 className='green-clr'>Frequently</h5>
                        <h4 className='theme-clr mt-1'>Asked Questions</h4>
                    </div>
                    <Row className='align-items-center'>
                        <Col lg={5} className='d-flex justify-content-end pe-xl-5 pe-lg-3 pe-0 '>
                            <div className='faq-lottie w-100'>
                                <Lottie className='faq-lottie'
                                    loop
                                    animationData={faq}
                                    play
                                />
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className='faqs-content'>
                                <Accordion defaultActiveKey="0" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>What types of collaborations are possible through the program?</Accordion.Header>
                                        <Accordion.Body>
                                        Collaborations can vary but often include joint marketing efforts, co-branded promotions, access to special pricing or packages, and more. The specifics depend on the partner's needs and the telecom provider.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What are the benefits of becoming a telecom partner?</Accordion.Header>
                                        <Accordion.Body>
                                            Staff augmentation is a great way for businesses to increase the efficiency of their operations by utilizing the skills and expertise of external professionals. It allows companies to fill in gaps in their workforce, add specialized skillsets, or scale up quickly when needed. By leveraging staff augmentation, companies can reduce costs and increase productivity while still maintaining quality standards.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Is there a cost associated with joining the program?</Accordion.Header>
                                        <Accordion.Body>
                                            Staff augmentation is a great way for businesses to increase the efficiency of their operations by utilizing the skills and expertise of external professionals. It allows companies to fill in gaps in their workforce, add specialized skillsets, or scale up quickly when needed. By leveraging staff augmentation, companies can reduce costs and increase productivity while still maintaining quality standards.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What level of support can we expect from your team once we become partners?</Accordion.Header>
                                        <Accordion.Body>
                                            Staff augmentation is a great way for businesses to increase the efficiency of their operations by utilizing the skills and expertise of external professionals. It allows companies to fill in gaps in their workforce, add specialized skillsets, or scale up quickly when needed. By leveraging staff augmentation, companies can reduce costs and increase productivity while still maintaining quality standards.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Can I reach out for additional information or clarification about the program?</Accordion.Header>
                                        <Accordion.Body>
                                            Staff augmentation is a great way for businesses to increase the efficiency of their operations by utilizing the skills and expertise of external professionals. It allows companies to fill in gaps in their workforce, add specialized skillsets, or scale up quickly when needed. By leveraging staff augmentation, companies can reduce costs and increase productivity while still maintaining quality standards.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Faqs
