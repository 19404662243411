import React, { useEffect, useRef } from "react";

const ThanksPage = (props) => {
  const myElement = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
  }, [props.flag]);

  return (
    <div className="Qoute__content">
      <div className="custom-container">
          <div className="row justify-content-center align-items-center">
            {/* <div className="col-xl-3">
              <div className="thanks_image">
                <img src={QouteForm} alt="QouteForm" />
              </div>
            </div> */}
            <div ref={myElement} className="col-lg-6 col-md-7">
              <div className="form_content qoute__form thanku-form">
                <div className="terms_condition d-flex align-items-start mt-0">
                  <div className="contact-headings text-center">
                    <h4 className="theme-clr mb-lg-3 mb-2">
                      Thanks for signing up
                    </h4>
                    <p>
                      Please check your email for your CRM credentials. If there are any questions, you can email us at{" "}
                      <a href="mailto:onboarding@gocomllink.com">
                        onboarding@gocomllink.com
                      </a>
                      or call us at <a href="tel:+18336031080">833 603-1080</a>,
                      our hours of operations are 9 AM - 1200 AM EST (Monday -
                      Friday)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default ThanksPage;
